import { render, staticRenderFns } from "./HeroSection.vue?vue&type=template&id=7d971e4b&scoped=true&"
import script from "./HeroSection.vue?vue&type=script&lang=ts&"
export * from "./HeroSection.vue?vue&type=script&lang=ts&"
import style0 from "./HeroSection.vue?vue&type=style&index=0&id=7d971e4b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d971e4b",
  null
  
)

export default component.exports